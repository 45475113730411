import { render, staticRenderFns } from "./homeSectionTryNow.vue?vue&type=template&id=2cdf44a3&scoped=true&"
import script from "./homeSectionTryNow.vue?vue&type=script&lang=js&"
export * from "./homeSectionTryNow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cdf44a3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VImg,VSheet})
