<template>
  <page-section>

    <v-row>
      <template   v-for="(item , index) in items" >
        <v-col :cols="!SM ? index % 2 === 0 ? 5 : 7  : 12" :key="index + 'first'" class="my-0 py-0" >
          <possibility-card-variant
              class="mt-10"
              :index="index"
              :title="item.title"
              :text="item.text"
              :image="item.image"
              :text-height="item.cols < 12 ? '100px' : null"
              :image-height="item.cols < 12 ? '350px' : '655px'"
              :icon="item.icon"
              first
          />
        </v-col>
        <v-col :cols="!SM ? index % 2 === 0 ? 7 : 5 : 12"
               :class="[{'pl-6' : !SM}]"
               :key="index + 'first'"
               class="my-0 py-0"
        >
          <possibility-card-variant
              class="mt-10"
              :index="index"
              :title="item.title"
              :text="item.text"
              :image="item.image"
              :icon="item.icon"
              :text-height="item.cols < 12 ? '100px' : null"
              :image-height="item.cols < 12 ? '350px' : '655px'"
              second
          />
        </v-col>
      </template>

    </v-row>


  </page-section>
</template>

<script>
import possibilityCardVariant from "@/components/pages/homepage_westudy/home/sections/UI/possibilityCardVariant";
export default {
  name: "homeSectionPossibilitiesVariant",
  components : {
    possibilityCardVariant
  },
  data() {
    return {
      items : [
        { title : 'features.crm.title',
          text : 'features.crm.text',
          image : 'img/features/feature_6.png',
          cols : 12,
          icon : "mdi-chart-box-outline"
        },
        { title : 'features.payment.title',
          text : 'features.payment.text',
          image : 'img/features/feature_payment.png',
          cols : 5,
          icon : "mdi-account-credit-card"
        },
        { title : 'features.converter.title',
          text : 'features.converter.text',
          image : 'img/features/feature_8.png',
          cols : 7,
          icon : "mdi-television-play"
        },
        { title : 'features.discount.title',
          text : 'features.discount.text',
          image : 'img/features/feature_7.png',
          cols : 7,
          icon : "mdi-brightness-percent"
        },
      ]
    }
  }
}
</script>

<style scoped>

</style>