<template>
  <!-- CLIENTS LOGOS SLIDER-->
  <page-section >

    <ws-slider
        :items="sliderItems"
        class="mt-5"
        :height="!SM ? 480 : null"
        width="100%"
        full-width
        display-dots
        :bottom-arrows="SM"
    >
      <template #item="{item,sectionIndex}">

        <div class="d-flex justify-center align-center fill-height "
             :class="[{ 'pa-3' : SM }]"
        >
          <div style="max-width: 900px; width: 100%">

            <h1 :style="`color : ${wsDARKER}; font-size: ${!SM ? 42 : 19}px`"
                :class="[{ 'text-center' : !SM }]"
            >
              “{{ $t(`homepage.sections.testimonials.${item.section}.${sectionIndex}.text`) }}”
            </h1>

            <v-sheet :width="!SM ? '900' : null" color="transparent" class="justify-center mt-8"
                     :class="[{'d-flex' : !SM }]"
            >
              <v-sheet color="transparent" width="250px" class="d-flex ">
                <div class="d-flex justify-center py-3">
                  <v-img
                      height="62"
                      max-width="62"
                      width="62"
                      class="mr-4"
                      style="border-radius: 50% !important;"
                      transition="xxx"
                      :src="`/library/img/clients/authors/${item.section}/${sectionIndex}.png` "
                  />
                  <div class="d-flex align-center">
                    <div>
                      <h4 :style="`color : ${wsACCENT}`" >{{ $t(`homepage.sections.testimonials.${item.section}.${sectionIndex}.author`) }}</h4>
                      <h5 class="mt-1" :style="`color : ${wsACCENT}`" >{{ $t(`homepage.sections.testimonials.${item.section}.${sectionIndex}.position`) }}</h5>
                    </div>
                  </div>

                </div>
              </v-sheet>
              <v-divider :vertical="!SM" :style="`border-color : ${wsDARKLIGHT};  !important`" :class="!SM ? 'mx-8' : 'my-4'"></v-divider>
              <v-sheet color="transparent" width="250px" class="d-flex "
                       :class="[{ 'pb-12' : SM }]"
              >
                <div class="d-flex justify-center align-center py-3">
                  <v-img
                      v-if="!item.item.noCircle && !item.item.noLogo"
                      height="62"
                      max-width="62"
                      width="62"
                      class="mr-4"
                      style="border-radius: 50% !important;"
                      transition="xxx"
                      :src="`/library/img/clients/logos/${item.section}/${sectionIndex}.png`"
                  />
                  <div  v-if="!item.item.noCircle" class="d-flex align-center">
                    <div>
                      <h4 :style="`color : ${wsACCENT}`" >{{ $t(`homepage.sections.testimonials.${item.section}.${sectionIndex}.title`) }}</h4>
                    </div>
                  </div>
                  <v-img
                      v-else-if="!item.item.noLogo"
                      height="64"
                      max-height="64"
                      width="300"
                      contain
                      class="py-2"
                      style="background-position: left"
                      :src="`/library/img/clients/logos/${item.section}/${sectionIndex}.png`"
                      transition="xxx"
                  />

                </div>
              </v-sheet>
            </v-sheet>
          </div>
        </div>
      </template>
    </ws-slider>

  </page-section>


</template>

<script>
export default {
  name: "homeSectionClientLogos",
  components : {
  },
  data() {
    return {

      swiperOption : {
        slidesPerView: 2,
        breakpoints: {
          1024: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
          },
          640: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          }
        },
        autoplay: {
          delay: 2500,
          stopOnLastSlide: false,
          disableOnInteraction: true,
          loop: true,
        },
        effect: "slide",

        spaceBetween : 16,
        navigation : {
          nextEl : '.clients-swiper-next',
          prevEl : '.clients-swiper-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          clickable : true,
          // renderBullet: (idx, className) =>
          //     `<v-btn small href="#" class="noCaps ${className}">
          //     ${idx}
          //     </v-btn>`
        }
      },
    }
  },
  computed : {
    sliderItems() {
      return  [
        { text : "homepage.sections.testimonials.universities.title",
          value : 'universities',
          items : [
            { value : '1' , background : this.wsLIGHTCARD ,   },
            { value : '2' , background : this.wsLIGHTCARD ,   },
            { value : '3' , background : this.wsLIGHTCARD ,   },
          ]
        },
        { text : "homepage.sections.testimonials.online_schools.title",
          value : 'online_schools',
          items : [
            { value : '4'  , background : this.wsLIGHTCARD ,   },
            { value : '5'  , background : this.wsLIGHTCARD ,   },
            { value : '6'  , background : this.wsLIGHTCARD ,   },
            { value : '7'  , background : this.wsLIGHTCARD , noCircle : true      },
            { value : '8'  , background : this.wsLIGHTCARD ,   },
            { value : '9'  , background : this.wsLIGHTCARD ,   },
            { value : '10' , background : this.wsLIGHTCARD , noLogo : true  },
          ]
        },
        { text : "homepage.sections.testimonials.language.title",
          value : 'language',
          items : [
            // { value : '11' , background : this.wsLIGHTCARD , noLogo : true},
            { value : '12' , background : this.wsLIGHTCARD , noLogo : true},
            { value : '13' , background : this.wsLIGHTCARD , noLogo : true }
          ]
        },
        { text : "homepage.sections.testimonials.coaches.title",
          value : 'coaches',
          items : [
            { value : '14' , background : this.wsLIGHTCARD },
            { value : '15' , background : this.wsLIGHTCARD , noLogo : true},
            { value : '16' , background : this.wsLIGHTCARD , noLogo : true }
          ]
        },
        { text : "homepage.sections.testimonials.companies.title",
          value : 'companies',
          items : [
            { value : '7' , background : this.wsLIGHTCARD ,  img : 'sisters'  ,   },
          ]
        },

      ]
    },

    testimonials() {
      return [
        {
          img : 'chukhray',
          business_alias : '',
        },
        {
          img : 'kihteva',
          business_alias : '',
        },
        {
          img : 'tsyos',
          business_alias : '',
        },
        {
          img : 'lutsai',
          business_alias : 'School-Lutsai',
        },
        {
          img : 'kiparenko',
          business_alias : '',
        }
      ]
    }
  }
}
</script>

<style scoped>
h4 {
  font-size: 14px !important;
}
</style>