<template>
  <page-section
      :title="$t('homepage.sections.cases.title')"
      :subtitle="$t('homepage.sections.cases.text')"
  >
    <v-row class="mt-12">
      <v-col cols="12" md="3" v-for="(icon, item ) in casesSelect" :key="item" >
        <v-icon large :color="wsATTENTION">
          mdi-{{ icon }}
        </v-icon>
        <h3 style="font-size: 19px" :style="`color : ${wsDARKER}`" class="mt-7">
          {{ $t(`homepage.sections.cases.${item}.title`) }}
        </h3>
        <h4 class="font-weight-regular mt-2 mb-10">
          {{ $t(`homepage.sections.cases.${item}.text`) }}
        </h4>
      </v-col>
    </v-row>
  </page-section>
</template>

<script>
export default {
  name: "homeSectionCases",
  data() {
    return {
      casesSelect : {
        education_organisations : 'bank',
        couches : 'account',
        experts : 'account-multiple',
        online_schools : 'school',
        brands : 'chart-pie',
        companies : 'basket',
        info_businessman : 'camera-account',
        beginners : 'account-star',
      }
    }
  }
}
</script>

<style scoped>

</style>